import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ContentLayout } from "layouts/ContentLayout";
import { Vacancy } from "components/redezign/Vacancy";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { fetchVacancy } from "services/store/modules/vacancy/actions";
import { RouteParams } from "common/commonTypes/types";
import {
  selectVacancyData,
  selectVacancy,
} from "services/store/modules/vacancy/selectors";
import { NotificationStatus } from "components/UI/Notifications/Notification";
import { fetchCandidateDictionaries } from "services/store/modules/candidate/actions";
import { fetchPositionsRelations } from "services/store/modules/settings/actions";
import { getCompanySettings } from "services/store/modules/company";

export const VacancyPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id: vacancyId } = useParams<RouteParams>();

  const vacancy = useAppSelector(selectVacancyData);
  const { loading, error } = useAppSelector(selectVacancy);

  useEffect(() => {
    Promise.all([
      dispatch(fetchVacancy({ vacancyId })),
      dispatch(fetchCandidateDictionaries()),
      dispatch(fetchPositionsRelations()),
      dispatch(getCompanySettings()),
    ]);
  }, []);

  return (
    <ContentLayout
      centralColumn={{
        content: (
          <>
            {vacancy ? (
              <Vacancy vacancyId={vacancyId} vacancy={vacancy} />
            ) : (
              <NotificationStatus
                loading={loading}
                error={error}
                notFoundText="Вакансия не найдена"
              />
            )}
          </>
        ),
      }}
    />
  );
};
