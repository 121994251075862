import { gql } from "@apollo/client";

export const AUTH_USER_AVITO = gql`
  mutation authUserAvito($authCode: String!) {
    authUserAvito(authCode: $authCode) {
      ... on UserType {
        id
        avitoAccountId
        email
        city
        companyId
        deletedAt
        createdAt
        updatedAt
        secondName
        phone
        role
        id
        firstName
        mangoId
        email
        departmentId
        company {
          name
          id
          owner {
            id
          }
          phone
          email
        }
        tz
        department {
          id
          name
          owner {
            id
          }
        }
        oktellLogin
      }
      ... on Error {
        statusCode
        message
      }
    }
  }
`;
