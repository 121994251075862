import { urlExpression } from "common/const/regexp";
import { UrlSearchParams } from "../commonTypes/types";

export const getIsLink = (link: string) => {
  const regex = new RegExp(urlExpression);

  return regex.test(link);
};

export const getUrl = (route: string, query = "") =>
  `https://${route}${query ? `?${query}` : ""}`;

export function getUrlParams(): UrlSearchParams {
  const params = new URLSearchParams(window.location.search);
  const result: UrlSearchParams = {};

  params.forEach((value, key) => {
    result[key as keyof UrlSearchParams] = value;
  });

  return result;
}

type Url = string | URL;

export const windowOpen = (url: Url, target?: string) => {
  window.open(url, target);
};

export const windowLocationAssign = (url: Url) => {
  window.location.assign(url);
};

export const windowLocationReplace = (url: Url) => {
  window.location.replace(url);
};

export const windowLocationReload = () => {
  window.location.reload();
};

export const windowHistoryReplaceState = <T extends object>(
  data: T,
  unused: string,
  url?: Url
) => {
  window.history.replaceState(data, unused, url);
};

export const windowHistoryPushState = <T extends object>(
  data: T,
  unused: string,
  url?: Url
) => {
  window.history.pushState(data, unused, url);
};
