import { gql } from "@apollo/client";

export const GET_AVITO_APP = gql`
  query getAvitoApp($userId: Int!) {
    getAvitoApp(userId: $userId) {
      ... on AvitoAppType {
        id
        applicationClientId
        avitoApplicationId
        departmentId
      }
      ... on Error {
        statusCode
        message
      }
    }
  }
`;
