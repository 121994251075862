import { UrlSearchParams } from "common/commonTypes/types";
import queryString, { StringifyOptions, UrlObject } from "query-string";

/**
 * '?foo=bar' => {foo: 'bar'}
 */
export const parseQuery = (query: string): UrlSearchParams => {
  return queryString.parse(query);
};

/**
 * {foo: 'bar', like: 'pizza'} => '?foo=bar&ilike=pizza'
 */
export const stringifyQuery = (
  query: Record<string, unknown>,
  options?: StringifyOptions
) => {
  return queryString.stringify(query, options);
};

/**
 * queryString.stringifyUrl({url: 'https://foo.bar?foo=baz', query: {foo: 'bar'}});
 * => 'https://foo.bar?foo=bar'
 */
export const stringifyUrl = (query: UrlObject, options?: StringifyOptions) => {
  return queryString.stringifyUrl(query, options);
};
